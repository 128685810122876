import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public getFeatureToggleConfig = (): Observable<FeatureToggleConfig> => {
    return this.httpClient.get<FeatureToggleConfig>(`${environment.apiUrl}/v2/feature-toggles`);
  }
}

export interface FeatureToggleConfig {
  frontPlateOrdersV2Enabled: boolean;
}
